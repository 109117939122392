import * as React from "react"
import Layout from "../components/layout/"
import { Container, Row, Col} from "react-bootstrap"
import { Helmet } from 'react-helmet';

// markup
const KommunikationPage = () => {

  return (
    <Layout>
      <Helmet>
        <title>Kommunikation - Unternehmensgruppe Urbas</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="overflow-hidden" style={{height: '25vh'}}>
            <div className="bg-dark-primary parallelogram-header1-sub">
              <div className="unskew-header1-sub">
                <div id="rect-sub">
                  <div className="p-3">
                    <h1>
                      Kommunikation
                      <br />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 p-0 ">
        <div className="bg-ultralight m-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
            <Row>
              <Col>
                <h4>Firmenprofil</h4>
                <div className="p-2">
                  <p>
                    Am Anfang stand die Gründung der O.V.H. Urbas im Jahr 1989.
                    Die Hauptgeschäftsfelder lagen in der Entwicklung von Lösungen
                    in den Bereichen Telekommunikation, Netzwerktechnik, EDV/Computerhardware und Gebäudetechnik.
                    Anfang der neunziger Jahre gewann der Sicherheitsaspekt sowohl bei Daten,
                    als auch bei Gebäuden immer mehr an Bedeutung und wurde auch
                    ein fester Bestandteil des Leistungsspektrums von Urbas.
                    <br></br>
                    1997 wurde das Unternehmen in die heutige <b className="font-primary">Urbas Daten & Kommunikation</b> umbenannt.
                  </p>
                  <br></br>
                  <p>
                    Heute ist <b className="font-primary">Urbas Daten & Kommunikation</b> in den Bereichen Telekommunikationssysteme,
                    Standortvernetzung, Haustechnik, Gebäudeüberwachung,
                    Gebäudesicherung und Infotainment, nicht zuletzt wegen ihrer in
                    die Zukunft ausgerichteten Strategie, ein zuverlässiger Partner
                    für Behörden, Verbände sowie namhafte Unternehmen des Handels und der Industrie.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Container className="pt-3 p-0 pb-4">
        <div className="bg-ultralight m-1 p-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
              <Row>
                <Col>
                  <h4>Leistungen</h4>
                  <div className="p-2">
                    <ul>
                      <li>EDV-Systeme</li>
                      <li>Gebäude- und Anlagensicherung</li>
                      <li>Gebäudetechnik</li>
                      <li>Netzwerkplanung</li>
                      <li>Netzwerkadministration</li>
                      <li>Netzwerkadministration</li>
                      <li>Telefonanlagen</li>
                      <li>Videoüberwachung</li>
                      <li>Zertifizierte Gebäudeverkabelung</li>
                    </ul>
                  </div>
                  <hr></hr>
                </Col>
              </Row>
              <Row>
                <Col>
                    <h4>Referenzen</h4>
                      <div className="p-2">
                        <ul>
                          <li>BMW AG</li>
                          <li>Siemens AG</li>
                          <li>Auto König GmbH</li>
                          <li>abr Reisebüro Vertriebs GmbH</li>
                          <li>AOK Südbayern</li>
                          <li>Artes Assekuranzmakler GmbH</li>
                          <li>BMF GmbH</li>
                          <li>ETG J. Fröschl & Co. GmbH</li>
                          <li>Hela Kosmetik GmbH</li>
                          <li>HOB electronic</li>
                          <li>Innstadt Brauerei</li>
                        </ul>
                      </div>
                  </Col>
                  <Col>
                      <div className="p-2">
                        <ul>
                          <li>I & S Informationstechnik und Service GmbH</li>
                          <li>Poet Software Gmbh</li>
                          <li>SDRC GmbH</li>
                          <li>Schötz Autotechnik</li>
                          <li>Schweiger Reitsport</li>
                          <li>Socrates GmbH</li>
                          <li>Spanisches Kulturinstitut München</li>
                          <li>Spengler Partyservice</li>
                          <li>Süddeutsche Metall-Berufsgenossenschaft</li>
                          <li>WLS-Immobilien</li>
                          <li>Wullf Systemhaus AG</li>
                          <li>Ytong AG</li>
                        </ul>
                      </div>
                  </Col>
              </Row>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default KommunikationPage
